import { createSvgIcon } from "@mui/material";

const CustomNavIcon = createSvgIcon(
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 15H10V12H13.5V14.5L17 11L13.5 7.5V10H9C8.71667 10 8.47933 10.096 8.288 10.288C8.09667 10.48 8.00067 10.7173 8 11V15ZM12 22C11.75 22 11.5043 21.95 11.263 21.85C11.0217 21.75 10.8007 21.6 10.6 21.4L2.6 13.4C2.4 13.2 2.25 12.979 2.15 12.737C2.05 12.495 2 12.2493 2 12C2 11.7507 2.05 11.505 2.15 11.263C2.25 11.021 2.4 10.8 2.6 10.6L10.6 2.6C10.8 2.4 11.021 2.25 11.263 2.15C11.505 2.05 11.7507 2 12 2C12.2493 2 12.4953 2.05 12.738 2.15C12.9807 2.25 13.2013 2.4 13.4 2.6L21.4 10.6C21.6 10.8 21.75 11.021 21.85 11.263C21.95 11.505 22 11.7507 22 12C22 12.2493 21.95 12.4953 21.85 12.738C21.75 12.9807 21.6 13.2013 21.4 13.4L13.4 21.4C13.2 21.6 12.979 21.75 12.737 21.85C12.495 21.95 12.2493 22 12 22Z" fill="#336699" />
    </svg>,
    "CustomNavIcon"
);

export default CustomNavIcon;
