import { TrashType } from "../constants/trash";
import { Polygon } from "../constants/types";

export type BatchAIResultDb = {
    class: TrashType;
    box: string;
    points: string;
    score: number;
}

export type BatchAIResult = {
    ID: string;
    class: TrashType;
    box: Polygon;
    points: Polygon;
    score: number;
}

/**
 * Bounding box data serialized for saving in Firestore
 */
export type BoundingBoxDb = Omit<BatchAIResultDb, "points"> 

/**
 * Bounding box data deserialized
 */
export type BoundingBox = Omit<BatchAIResult, "points"> & {
    hidden: boolean;
    createdAt?: number;
};

/**
 * Prefix to the IDs of the bounding boxes HTML elements
 */
export const BBOX_ID_PREFIX = "bounding-box-";


/**
 * Turns an array of BoundingBoxDb into an array of BoundingBox
 */
export function deserializeBoundingBox(batchID: string, bboxes: BoundingBoxDb[] | undefined): BoundingBox[] | undefined {
    return bboxes?.map((bbox, index) => ({ 
        ...bbox, 
        ID: `${BBOX_ID_PREFIX}${batchID}-${index}`, 
        box: JSON.parse(bbox.box),
        hidden: false,
    }));
}

/**
 * Calculate the area of a bounding box relative to the total image size.
 * @returns The portion of the image covered by the box, between 0 and 1
 */
export function getBoundingBoxSurface(box: Polygon, imageWidth: number, imageHeight: number): number {
    const boxWidth = box[1][0] - box[0][0];
    const boxHeight = box[1][1] - box[0][1];
    const boxArea = boxWidth * boxHeight;
    const imageArea = imageWidth * imageHeight;
    if (!imageArea) alert("WARNING: Division by 0, please contact the IT support");
    return boxArea / imageArea;
}