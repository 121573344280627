import { createSvgIcon } from "@mui/material";

const HeaderIcon = createSvgIcon(
    <svg viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17 34V29M17 19V14M14.5 16.5H19.5M14.5 31.5H19.5M25.5 15L23.7658 19.5089C23.4838 20.2421 23.3428 20.6087 23.1235 20.9171C22.9292 21.1904 22.6904 21.4292 22.4171 21.6235C22.1087 21.8428 21.7421 21.9838 21.0089 22.2658L16.5 24L21.0089 25.7342C21.7421 26.0162 22.1087 26.1572 22.4171 26.3765C22.6904 26.5708 22.9292 26.8096 23.1235 27.0829C23.3428 27.3913 23.4838 27.7579 23.7658 28.4911L25.5 33L27.2342 28.4911C27.5162 27.7579 27.6572 27.3913 27.8765 27.0829C28.0708 26.8096 28.3096 26.5708 28.5829 26.3765C28.8913 26.1572 29.2579 26.0162 29.9911 25.7342L34.5 24L29.9911 22.2658C29.2579 21.9838 28.8913 21.8428 28.5829 21.6235C28.3096 21.4292 28.0708 21.1904 27.8765 20.9171C27.6572 20.6087 27.5162 20.2421 27.2342 19.5089L25.5 15Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>,
    "Header"
);

export default HeaderIcon;
