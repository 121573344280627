import React from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Stack, IconButton, Box } from '@mui/material';
import { Namespace } from '../../locales/translations';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import GoogleIcon from '../_include/icons/GoogleIcon';
import HeaderIcon from '../_include/icons/HeaderIcon';
import WazeAppIcon from '../_include/icons/WazeAppIcon';

interface NavigationDialogProps {
    open: boolean;
    onClose: () => void;
    coordinates: {
        lat: number;
        lng: number;
    };
}

const NavigationDialog: React.FC<NavigationDialogProps> = ({ open, onClose, coordinates }) => {
    const { t } = useTranslation([Namespace.TITLES]);

    const openGoogleMaps = () => {
        const url = `https://www.google.com/maps/dir/?api=1&destination=${coordinates.lat},${coordinates.lng}`;
        window.open(url, '_blank');
        onClose();
    };

    const openWaze = () => {
        const url = `https://www.waze.com/ul?ll=${coordinates.lat},${coordinates.lng}&navigate=yes`;
        window.open(url, '_blank');
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="xs"
            fullWidth
            sx={{
                borderRadius: '12px',
                overflow: 'hidden',
            }}
        >
            {/* Header Section */}
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ position: 'relative', py: 2 }}
            >
                {/* Centered Header Icon */}
                <HeaderIcon fontSize='large' />

                {/* Close Button */}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: '8px',
                        color: 'text.secondary',
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                    }}
                >
                    <Close />
                </IconButton>
            </Box>

            {/* Title */}
            <DialogTitle align="center" sx={{ fontWeight: '500' }}>
                {t('open_navigation', { ns: Namespace.TITLES })}
            </DialogTitle>

            {/* Content Section */}
            <DialogContent sx={{ px: 3, py: 2 }}>
                <Stack spacing={2}>
                    {[
                        { label: 'Google Maps', icon: <GoogleIcon />, action: openGoogleMaps },
                        { label: 'Waze', icon: <WazeAppIcon />, action: openWaze },
                    ].map(({ label, icon, action }) => (
                        <Button
                            key={label}
                            variant="contained"
                            disableElevation
                            onClick={action}
                            fullWidth
                            sx={{
                                fontSize: '16px',
                                fontWeight: '600',
                                padding: '10px 16px',
                                backgroundColor: '#FFFFFF',
                                color: '#414651',
                                border: '1px solid var(--Gray-300, #D5D7DA)',
                                boxShadow: '0px 1px 2px 0px rgba(10, 13, 18, 0.05)',
                                '&:hover': {
                                    backgroundColor: '#F5F5F5',
                                },
                                '&:active': {
                                    backgroundColor: '#FFFFFF',
                                },
                            }}
                            startIcon={icon}
                        >
                            {label}
                        </Button>
                    ))}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default NavigationDialog;
