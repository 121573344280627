import CloseIcon from '@mui/icons-material/Close';
import { Box, Slide } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useState, useRef } from "react";
import { getPictureURL, StorageType } from "../../helpers/storage";
import { BoundingBox } from '../../models/BoundingBox';
import { cleanCanvas, drawLabel, drawRectangle } from '../../helpers/draw';
import { isSortingError } from '../../helpers/trash';
import { WASTES_COLORS } from '../../constants/trash';
import { useScreenOrientation, useSortingRules } from '../../store/hooks';
import { Namespace } from '../../locales/translations';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type ImageComponentProps = {
    imagePath: string;
    boundingBoxes?: BoundingBox[];
};

export const ImageComponent = ({ imagePath, boundingBoxes }: ImageComponentProps) => {
    const { t } = useTranslation([Namespace.WASTES]);
    const [url, setUrl] = useState('');
    const [open, setOpen] = useState(false);

    const { errorsClasses, mergingMapping } = useSortingRules();

    // Separate refs for thumbnail and dialog
    const thumbnailCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const thumbnailImageRef = useRef<HTMLImageElement | null>(null);
    const dialogCanvasRef = useRef<HTMLCanvasElement | null>(null);
    const dialogImageRef = useRef<HTMLImageElement | null>(null);

    const [thumbnailImageLoaded, setThumbnailImageLoaded] = useState(false);
    const [dialogImageLoaded, setDialogImageLoaded] = useState(false);

    const orientation = useScreenOrientation();

    useEffect(() => {
        const fetchUrl = async () => {
            const result = await getPictureURL(imagePath, StorageType.BATCHES);
            setUrl(result || '');
        };
        fetchUrl();
    }, [imagePath]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDialogImageLoaded(false);
    };

    const drawBoundingBoxes = (
        canvas: HTMLCanvasElement | null,
        image: HTMLImageElement | null,
        showLabel: boolean,
    ) => {
        const ctx = canvas?.getContext('2d');
        if (!canvas || !image || !ctx) return;

        // Set canvas dimensions to match image
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;

        cleanCanvas(ctx, canvas.width, canvas.height);

        if (boundingBoxes) {
            const resultsToDraw = boundingBoxes
                .filter(boundingBox => isSortingError(errorsClasses, boundingBox.class));

            for (let boundingBox of resultsToDraw) {
                const classToDraw = mergingMapping[boundingBox.class] ?? boundingBox.class;

                drawRectangle(
                    ctx,
                    boundingBox.box[0],
                    boundingBox.box[1],
                    WASTES_COLORS[classToDraw],
                    6
                );

                if (showLabel) { // display the lable for the bigger 
                    const translatedClass = t(boundingBox.class, {
                        ns: Namespace.WASTES,
                        count: 1,
                        context: "small"
                    });
                    drawLabel(
                        ctx,
                        boundingBox.box[0],
                        translatedClass,
                        22, "Roboto Mono",
                        WASTES_COLORS[classToDraw],
                        "#222222",
                    );
                }
            }
        }
    };

    // Draw thumbnail canvas
    useEffect(() => {
        if (thumbnailImageLoaded) {
            drawBoundingBoxes(thumbnailCanvasRef.current, thumbnailImageRef.current, false);
        }
    }, [thumbnailImageLoaded, boundingBoxes, errorsClasses, mergingMapping]);

    // Draw dialog canvas
    useEffect(() => {
        if (dialogImageLoaded && open) {
            drawBoundingBoxes(dialogCanvasRef.current, dialogImageRef.current, true);
        }
    }, [dialogImageLoaded, open, boundingBoxes, errorsClasses, mergingMapping]);

    return (
        <>
            <div style={{ position: 'relative' }}>
                <img
                    ref={thumbnailImageRef}
                    alt="batch"
                    src={url}
                    onClick={handleClickOpen}
                    onLoad={() => setThumbnailImageLoaded(true)}
                    width="100%"
                    height="100%"
                    style={{
                        objectFit: 'cover',
                        borderRadius: '0px',
                    }}
                />
                {thumbnailImageLoaded && (
                    <canvas
                        ref={thumbnailCanvasRef}
                        onClick={handleClickOpen}
                        width={thumbnailImageRef.current?.naturalWidth}
                        height={thumbnailImageRef.current?.naturalHeight}
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: '100%',
                            maxHeight: '100%',
                            objectFit: "contain",
                        }} />
                )}

            </div>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                PaperProps={{
                    style: {
                        margin: 0,
                        padding: 0,
                        maxWidth: '100vw',
                        maxHeight: '100vh',
                        overflow: 'hidden', // Prevent scrolling
                        backgroundColor: "#111"
                    }
                }}
            >
                <IconButton
                    edge="start"
                    color="white"
                    onClick={handleClose}
                    aria-label="close"
                    style={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        zIndex: 10,
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <Box
                    sx={orientation.startsWith("portrait") ? { // rotate image to display as landscape
                        transform: "rotate(90deg)",
                        transformOrigin: "right top",
                        width: "100vh",
                        height: "100vw",
                        position: "absolute",
                        top: "100%",
                        right: 0,
                    } : { // landscape mode: display normally
                        width: "100vw",
                        height: "100vh",
                        position: "absolute",
                        right: 0,
                        top: 0,
                    }}
                >
                    <img
                        ref={dialogImageRef}
                        alt="batch enlarged"
                        src={url}
                        onLoad={() => setDialogImageLoaded(true)}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                        }}
                    />
                    {dialogImageLoaded && (
                        <canvas
                            ref={dialogCanvasRef}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: "contain",
                            }}
                        />
                    )}
                </Box>
            </Dialog>
        </>
    );
};