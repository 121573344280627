import React, { useState } from 'react';
import { ListItem, Checkbox, ListItemText, Divider } from '@mui/material';
import { formatAddress } from '../../helpers/geo';
import { useTranslation } from 'react-i18next';
import { Namespace } from '../../locales/translations';
import { MissionStatus } from '../../models/Missions';
import { useAppSelector } from '../../store/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { AppRoutes } from '../../constants/routes';
import AddressReport from './AddressReport/AddressReport';
import { selectMissionAddressById } from '../../store/reducers/missions/addresses';
import { EntityId } from '@reduxjs/toolkit';
import NavigationDialog from './NavigationDialog';
import CustomNavIcon from '../_include/icons/CustomNavIcon';

interface MissionAddressItemProps {
    ID: EntityId;
}

const MissionAddressItem: React.FC<MissionAddressItemProps> = ({ ID }) => {
    const { t } = useTranslation([Namespace.GLOSSARY]);
    const navigate = useNavigate();
    const { missionID } = useParams<{ missionID: string }>();
    const missionCompleted = useAppSelector((state) => state.mission.selected.selectedMission?.status === MissionStatus.COMPLETED);
    const addressData = useAppSelector(state => selectMissionAddressById(state, ID))!;

    const [isAddressReportOpen, setIsAddressReportOpen] = useState(false);
    const [isNavigationModalOpen, setIsNavigationModalOpen] = useState(false);

    const handleCheckboxChange = () => {
        if (addressData.visited) {
            setIsAddressReportOpen(false);
        } else {
            setIsAddressReportOpen(true);
        }
    };

    const handleAddressDetailClick = () => {
        if (missionID) {
            navigate(
                `/${AppRoutes.MISSIONS}/${missionID}/${AppRoutes.BATCH_DETAIL}/${addressData.addressKey}`
            );
        }
    };

    const handleNavigationIconClick = (event: React.MouseEvent) => {
        event.stopPropagation(); // Prevent list item click
        setIsNavigationModalOpen(true);
    };

    const errorsCountLabel = t('errors_count', {
        count: addressData.errorsCount,
        ns: Namespace.GLOSSARY,
    });
    const imagesCountLabel = t('images_count', {
        count: addressData.batchesCount,
        ns: Namespace.GLOSSARY,
    });


    return (
        <>
            <ListItem
                onClick={handleAddressDetailClick}
                sx={{
                    '&:hover': {
                        backgroundColor: 'action.hover',
                        cursor: 'pointer',
                    },
                }}
                secondaryAction={
                    <div onClick={handleNavigationIconClick}>
                        <CustomNavIcon />
                    </div>
                }
            >
                {!missionCompleted && (
                    <Checkbox
                        edge="start"
                        color='success'
                        disableRipple
                        checked={addressData.visited}
                        onChange={handleCheckboxChange}
                        onClick={(event) => event.stopPropagation()}
                    />
                )}
                <ListItemText
                    primary={formatAddress(addressData.address)}
                    secondary={`${errorsCountLabel} / ${imagesCountLabel}`}
                />
            </ListItem>
            <Divider />
            {/* Navigation Dialog */}
            <NavigationDialog
                open={isNavigationModalOpen}
                onClose={() => setIsNavigationModalOpen(false)}
                coordinates={{
                    lat: addressData.lat,
                    lng: addressData.lng
                }}
            />
            {/* Conditionally render the AddressReport dialog */}
            {isAddressReportOpen && (
                <AddressReport
                    open={isAddressReportOpen}
                    address={formatAddress(addressData.address)}
                    onClose={() => setIsAddressReportOpen(false)}
                    addressID={addressData.ID}
                />
            )}

        </>
    );
};

export default MissionAddressItem;
