import { AlertColor } from "@mui/material";
import { Point } from "../constants/types";
import { BatchAIResult } from "../models/BoundingBox";
import { DangerLevel } from "./geo";
import { WASTES_COLORS } from "../constants/trash";

/**
 * Colors representing the levels of threat represented by a number of sorting errors
 */
export const DANGER_COLORS = {
    /** 0 error */
    [DangerLevel.NONE]: "#5FB474",
    /** 1-3 errors */
    [DangerLevel.LOW]: "#ffc302",
    /** 4-6 errors */
    [DangerLevel.MEDIUM]: "#ff8f00",
    /** 7-10 errors */
    [DangerLevel.HIGH]: "#ff5b00",
    /** 11+ errors */
    [DangerLevel.VERY_HIGH]: "#ff0505",
};

/**
 * Extract the red, green and blue values from an hexadecimal string
 * The extract values are between 0 and 255.
 */
export function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : {
        r: 0,
        g: 0,
        b: 0,
    };
}

/**
 * Converts an hexadecimal string into a CSS "rgba" string,
 * applying the opacity passed as parameter
 * @param opacity The "a" parameter of the "rgba" string, setting the color's opacity
 */
export function hexToRgba(hex: string, opacity: number) {
    const { r, g, b } = hexToRgb(hex);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

/**
 * Remove all drawings made on a canvas on top of a given image
 */
export const cleanCanvas = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
    ctx.clearRect(0, 0, width, height);
}

/**
 * Draw a line between two points on a canvas
 * @param ctx The HTML canvas's 2D context
 * @param point1 First point
 * @param point2 Second point
 * @param color Color of the line
 * @param lineWidth Thickness of the line
 */
export const drawLine = (ctx: CanvasRenderingContext2D, point1: Point, point2: Point, color: string, lineWidth=2) => {
    //draw line
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(point1[0], point1[1]);
    ctx.lineTo(point2[0], point2[1]);
    ctx.stroke();
}

/**
 * Draw a rectangle given its diagonal from top-left to bottom-right
 * @param ctx The HTML canvas's 2D context
 * @param point1 Top-left point of the rectangle's diagona2
 * @param point2 Bottom-right point of the rectangle's diagonal
 * @param color Color of the rectangle's stroke
 */
export const drawRectangle = (ctx: CanvasRenderingContext2D, topLeft: Point, bottomRight: Point, color: string, lineWidth= 1) => {
    const [left, top] = topLeft;
    const [right, bottom] = bottomRight;
    
    //draw line
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.beginPath();
    ctx.moveTo(left, top);
    ctx.lineTo(right, top);
    ctx.lineTo(right, bottom);
    ctx.lineTo(left, bottom);
    ctx.lineTo(left, top);
    ctx.stroke();
}

/**
 * Draw a text over a colored background, for example a bounding box label
 * @param ctx The HTML canvas's 2D context
 * @param bottomLeft Baseline-left point for the text
 * @param text String for the label to write
 * @param fontSize Size of the font for the text
 * @param fontFamily Font family for the text
 * @param textColor Color for the text
 * @param backgroundColor Color for the background behind the text
*/
export const drawLabel = (ctx: CanvasRenderingContext2D, bottomLeft: Point, text: string, fontSize: number, fontFamily: string, textColor: string, backgroundColor: string) => {
    const font = `${fontSize}px "${fontFamily}"`;

    document.fonts.load(font).then(function () { // make sure font is loaded

        const [x, y] = bottomLeft;
        
        ctx.font = font;

        // add a bit of horizontal padding
        const paddedText = ` ${text} `;

        // measure the text
        const textSize = ctx.measureText(paddedText);

        // Fill the background
        ctx.fillStyle = backgroundColor;
        ctx.fillRect(x, y - fontSize, textSize.width, fontSize + 8); // add 8px to have vertical padding around text
        
        // add the text
        ctx.fillStyle = textColor;
        ctx.fillText(paddedText, x, y);
    });
}

/**
 * Draw the polygon masks of some AI results on a canvas on top of a Batch image
 * @param ctx The HTML canvas's 2D context 
 * @param results The list of results calculated by the AI
 * @param drawLabels Set to true to add a label on top of the mask with class and score in %
 */
export const drawPolygons = (ctx: CanvasRenderingContext2D, results: BatchAIResult[], drawLabels?: boolean) => {
    results.forEach(result => {
        const polygon = result.points;

        const firstPoint = polygon[0]; // Point
        ctx.beginPath();
        ctx.moveTo(firstPoint[0], firstPoint[1]);

        let topLeft = polygon[0]; // track point the most at the top and left

        for (let i = 1; i < polygon.length; i++) { // move line alongside all polygon points
            const point = polygon[i];
            ctx.lineTo(point[0], point[1]);

            if (point[0] < topLeft[0]) topLeft = [point[0], topLeft[1]]; // more to the left
            if (point[1] < topLeft[1]) topLeft = [topLeft[0], point[1]]; // more to the top
        }

        // go back to first point to close shape
        ctx.lineTo(firstPoint[0], firstPoint[1]);
        ctx.closePath();

        const fillColor = hexToRgb(WASTES_COLORS[result.class]);
        ctx.fillStyle = `rgba(${fillColor.r}, ${fillColor.g}, ${fillColor.b}, 0.2)`;
        ctx.strokeStyle = WASTES_COLORS[result.class];
        ctx.lineWidth = 2;
        ctx.fill();
        ctx.stroke();

        if (drawLabels) { // draw labels with class and score in %
            const labelText = `${result.class}: ${Math.round(result.score * 10000) / 100}%`;
            const labelFontSize = 16;
            ctx.font = `${labelFontSize}px Courier`;
            const labelBottomPadding = 6;
            const labelRectHeight = labelFontSize + labelBottomPadding;

            // add dark background behind text
            const labelWidth = ctx.measureText(labelText).width; /// width in pixels
            ctx.fillStyle = "#333";
            ctx.fillRect(topLeft[0], topLeft[1] - labelRectHeight - 1, labelWidth, labelRectHeight);

            // draw label
            ctx.fillStyle = WASTES_COLORS[result.class];
            ctx.fillText(labelText, topLeft[0], topLeft[1] - labelBottomPadding);
        }
    });
};


export function getAlertColor(level: DangerLevel): AlertColor {
    switch (level) {
        case DangerLevel.VERY_HIGH:
        case DangerLevel.HIGH:
            return "error";

        case DangerLevel.MEDIUM:
        case DangerLevel.LOW:
            return "warning";
    }
    
    return "success";
}

export const getGradient = (direction: "vertical" | "horizontal", color1: string, color2: string) => `linear-gradient(${direction === "vertical" ? "to bottom" : "to right"}, ${color1}, ${color2})`;
